<template>
  <div class="app-container" style="margin-bottom: 20px">
    <!-- -------万恶分割线------- -->
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="120px"
      >
      <el-form-item label="分类图片" prop="imgId">
          <el-upload
            class="avatar-uploader"
            action="http://up-z0.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="100%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="分类名称" prop="typeName">
          <el-input v-model="formData.typeName" style="width: 90%" />
        </el-form-item>
      
        <el-form-item label="排序" prop="sort" v-if="typeId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <div style="margin-bottom: 20px">
      <el-row>
           <el-col :span="12" align="left">
          <el-button class="el-icon-edit" type="primary" @click="goback"
            >返回</el-button
          >
        </el-col>
        <el-col :span="12" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addType"
            >添加</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="tableData"
        border
        style="width: 100%"
        ref="tableData"
        class="emp_table"
        v-if="tableData"
      >
      <el-table-column
          align="center"
          prop="imgId"
          label="分类图片"
          width="200"
        >
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
              :src="'http://file.innopinenut.com/' + scope.row.imgId "
                :preview-src-list="[
                  'http://file.innopinenut.com/' + scope.row.imgId
                ]"
              >   
              <div slot="error" class="image-slot">
             <img src="../../assets/暂无.jpg"   style="width: 100%" alt="">
      </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="typeName"
          label="分类名称"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          align="center"
          prop="typeGroup"
          label="分类类型"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.typeGroup | fz }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="upStatus"
          label="上下架状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sort"
          label="排序"
          :show-overflow-tooltip="true"
        />

        <el-table-column align="center" prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | time }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="modifyType(scope.row)">
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="setTypeStatus(scope.row)"
            >
              {{ scope.row.upStatus == 1 ? "上架" : "下架" }}
            </el-button>

            <el-button type="text" size="small" @click="del(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getTypes"
      />
    </div>
  </div>
</template>

<script>
import {
  getTypes,
  addType,
  modifyType,
  setTypeStatus,
  delType,
  getUploadToken
} from "../../request/http";

export default {
  data() {
    return {
      imageUrl:"",
      postData: {
        token: '',
        key: '' //上传的Logo
      },
      tableData: "", //列表
      total: 0, //总数
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      title: "",
      showDialog: false,
       typeId:null,
      formData: {
        typeName: "",
        typeGroup: "PDF",
        imgId:""
      },
    
      rules: {
        typeName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
        typeGroup: [
          { required: true, message: "分类类型不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getTypes();
  },
  mounted() {},
  methods: {
        goback(){
        this.$router.go(-1);
    },
    // 按分组获取分类列表
    async getTypes() {
      this.queryParams.typeGroup = "PDF";
      let res = await getTypes(this.queryParams);
      console.log(res);
      if (res.code == 0) {
        this.tableData = res.data;
        this.total = res.count;
      }
    },
      // 上传图片方法
  handleAvatarSuccess(res, file) {
      console.log('上传成功', res, file)
      this.imageUrl = 'http://file.innopinenut.com/' + res.key
      this.formData.imgId = res.key
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传图片时获取Token失败!')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
   



    //设置上下架
    async setTypeStatus(val) {
      let id = val.typeId;
      let { code } = await setTypeStatus({ typeId: id });
      //   console.log(code);

      code == 0 &&
        this.$message({
          type: "success",
          message: "设置成功",
        });

      this.getTypes();
    },
    // 打开添加的窗口

    addType() {
      this.title = "添加PDF分类信息";
      this.showDialog = true;
    },
    //编辑
    modifyType(val) {
      this.title = "编辑PDF分类信息";
      console.log(val);
      this.typeId=val.typeId
      val.imgId?  this.imageUrl = "http://file.innopinenut.com/" +val.imgId  :this.imageUrl=""
      this.formData = {
        typeId: val.typeId,
        typeName: val.typeName,
        sort: val.sort,
        imgId:val.imgId
      };
      this.showDialog = true;
    },
    // 删除类别
    del(val) {
      console.log(val);
      let id = val.typeId;
      this.$confirm("此操作将删除该分类信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await delType({ typeId: id });
          // console.log(res);
          res.code == 0 &&
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          const totalPage = Math.ceil(
            (this.total - 1) / this.queryParams.pageSize
          );
          this.queryParams.page =
            this.queryParams.page > totalPage
              ? totalPage
              : this.queryParams.page;
          this.queryParams.page =
            this.queryParams.page < 1 ? 1 : this.queryParams.page;
         this.getTypes();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(async() => {
          if (this.formData.typeId) {
           let {code,msg} = await modifyType(this.formData);
           if(this.formData.typeId&&code==0){
            this.$message.success("编辑成功");
           }
           else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          } else {
            
            this.formData.typeGroup="PDF"
            let {code, msg} = await addType(this.formData);
            if (code == 0) {
            this.$message.success("新增成功,请上架该分类");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          }
          this.getTypes();
          this.showDialog = false;
        }
          
        )
       
    },
    
    
    //弹窗取消函数
    btnCancel() {
      this.formData = {
        typeId: "",
        typeName: "",
        sort: "",
        imgId:''
      };
  this.imageUrl=""
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>
   .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
